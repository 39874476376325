import i18n from '../i18n';
import { createToaster } from '@meforma/vue-toaster';
import Cookies from 'js-cookie';
import { generateRandomString } from '@/helpers/stringUtiles';
import { TIPO_CODIGO_DESCUENTO } from '@/constants/reservaConstants';
import currency from 'currency.js';
import moment from 'moment';
import UsuarioApiService from '@/services/api/skiandnight/UsuarioApiService';

const { t } = i18n.global;

//TODO Usar informacion de filtros de la api

export const selectorDescripcionGrupos = tipoGrupo => {
	switch (tipoGrupo) {
		case 'colegios':
			return t('viajesGrupos.de0a17');

		case 'familias':
			return t('viajesGrupos.todosPublicos');

		case 'premium':
			return t('viajesGrupos.todosPublicos');

		case 'trabajadores':
			return t('viajesGrupos.mas25anios');

		default:
			return t('viajesGrupos.de18a25');
	}
};

export const selectorIdGrupos = tipoGrupo => {
	switch (tipoGrupo) {
		case 'colegios':
			return 6;
		case 'familias':
			return 5;
		case 'premium':
			return 7;
		case 'trabajadores':
			return 4;
		default:
			return 1;
	}
};

export const filtrarViajesPorFestival = (festival, listaViajes) => {
	let totalAux = listaViajes;

	festival &&
		(totalAux = totalAux.filter(viajeGrupo => {
			if (viajeGrupo.tipos[0] != undefined) return viajeGrupo.festival == festival;
		}));

	return totalAux;
};


export function saveComercialId(comercialId) {
	 if (comercialId) {
		 UsuarioApiService.addAccesoLink(comercialId);
		 Cookies.set('comercialId', comercialId, { expires: 2 });
	 }
}

export function calculaDuracionViaje(viaje) {
	let fechaInicio = Date.parse(viaje.fechaInicio);
	let fechaFin = Date.parse(viaje.fechaFin);
	return Math.ceil((fechaFin - fechaInicio) / (1000 * 3600 * 24));
}

export function calculaPrecioBase(reserva) {
	if (reserva.precioBasico === 0 || reserva.precioBasico === undefined) {
		return currency(reserva.precioBaseViaje)
				.add(reserva.precioTotalCupo)
				.add(reserva.incrementoHotel)
				.add(reserva.precioServicios)
				.add(reserva.precioComplementos);
	} else {
		return currency(reserva.precioBasico).add(reserva.precioServicios).add(reserva.precioComplementos);
	}
}

export function calculaPrecioForServicioWithCantidad(swc, relativeToPB = true) {
	let cantidad = swc.cantidad;
	let precio = swc.servicio.precio;
	if (swc.servicio.paqueteBasico) {
		cantidad--;
		precio = swc.servicio.precioTeorico
	} else if (swc.categoriaPaqueteBasico) {
		precio = swc.servicio.precioTeorico;
	}
	precio = currency(precio).multiply(cantidad);
	if (swc.categoriaPaqueteBasico && !swc.servicio.paqueteBasico && !swc.servicio.nulo && relativeToPB) {
		precio = currency(precio).subtract(swc.servicioPB.precioTeorico);
	}
	return precio;
}

export function calculaPrecioServicios(reserva) {
	return Object.values(reserva.serviciosWithCantidad).reduce((accum, swc) => calculaPrecioForServicioWithCantidad(swc, true).add(accum), 0);
}

export function calculaPrecioComplementos(reserva) {
	if (reserva.complementos) {
		return Object.values(reserva.complementos).reduce((accum, complemento) => accum.add(complemento.importe), currency(0));
	} else {
		return 0;
	}
}

export function calculaPrecioGastos(reserva) {
	if (reserva.gastos) {
		return Object.values(reserva.gastos).reduce((accum, gasto) => accum.add(gasto.importe), currency(0));
	} else {
		return 0;
	}
}

export function calculaPrecioTotalReservas(reservas) {
	return reservas?.reduce((accum, reserva) => accum.add(calculaPrecioTotal(reserva)), currency(0));
}

export function calculaPrecioTotal(reserva, includeDescuento = true) {
	let precioTotal = currency(reserva.precioTotal);
	if (includeDescuento) {
		precioTotal = precioTotal.subtract(reserva.descuento || 0);
	}
	return precioTotal;
}

export function calculaPrecioTotalSinTasasInternasReservas(reservas) {
	return reservas?.reduce((accum, reserva) => accum.add(calculaPrecioTotalSinTasasInternas(reserva)), currency(0));
}

export function calculaPrecioTotalSinTasasInternas(reserva, includeDescuento = true) {
	let precioTotalSinTasasInternas = currency(reserva.precioTotalSinTasasInternas);
	if (includeDescuento) {
		precioTotalSinTasasInternas = precioTotalSinTasasInternas.subtract(reserva.descuento || 0);
	}
	return precioTotalSinTasasInternas;
}

export function calculaDescuento(descuentoData, precioBase) {
	let descuento = currency(descuentoData.cantidad);
	if (descuentoData.tipoCantidad === TIPO_CODIGO_DESCUENTO.PORCENTAJE) {
		return descuento.divide(100).multiply(precioBase);
	} else {
		return descuento;
	}
}

export function buildFormPreguntasCrearReserva(formReserva) {
	const nameGrupo = generateRandomString();
	return {
		/*INDICAR A BACKEND QUE ESTOS CAMPOS YA NO SEAN OBLIGATORIOS*/
		nombreGrupo: nameGrupo /*formReserva.nombreGrupo*/,
		// conociste: 'PRUEBA' /*formReserva.dondeConociste*/,
		comercial: formReserva.comercial,
		comercialReferenciadoId: Cookies.get('comercialId') || null,
		// estudiante: formReserva.esEstudiante,
		// universidad: formReserva.esEstudiante ? formReserva.universidadSeleccionada : '',
		// titulacion: formReserva.esEstudiante ? formReserva.universidadSeleccionada : 'vacio',
		trabajo: !formReserva.esEstudiante ? formReserva.trabajoSeleccionado : '',
		// preguntaLibreRespuesta: formReserva.preguntaLibre,
	};
}

export function buildFormCodigoCupoCrearReserva(parametrosRuta, codigoIntegracion) {
	return {
		codigoGrupo: parametrosRuta.codigoGrupo || null,
		cupo: {
			hotel: parseInt(parametrosRuta.hotelId),
			precioTotal: parseFloat(parametrosRuta.cupoIncremento),
			tipoHabitacion: parseInt(parametrosRuta.tipoHabId),
			numPlazas: parseInt(parametrosRuta.numPlazas),
			codigoIntegracion: codigoIntegracion
		},
		regimen: parametrosRuta.regimen || null,
	};
}

export function buildListaReservasCrearReserva(listaReservas, esModificarReserva = false) {
	const reservasDtoArray = [];

	listaReservas.forEach(reserva => {
		const serviciosAux = Object.values(reserva.serviciosWithCantidad).map(swc => { return {id: swc.servicio.id, cantidad: swc.cantidad}});
		const reservaDto = {
			reservaPropia: reserva.reservaPropia || false,
			nombre: reserva.reservaPropia ? '' : reserva.usuario.nombre,
			apellidos: reserva.reservaPropia ? '' : reserva.usuario.apellido,
			fechaNacimiento: reserva.usuario.fecha ? moment(reserva.usuario.fecha).format('YYYY-MM-DD') : '1987-10-06',
			observaciones: '',
			serviciosWithCantidad: serviciosAux,
		};

		if (esModificarReserva) {
			reservaDto.id = reserva.agregaModificar ? null : reserva.id;
		}

		reservasDtoArray.push(reservaDto);
	});

	return reservasDtoArray;
}

export function validacionNombresReservas(listaReservas) {
	const toaster = createToaster({ position: 'top-right' });
	let errorNombres = [];
	listaReservas.map(reserva => {
		if (!reserva.reservaPropia) {
			if (reserva.usuario.nombre === '') {
				toaster.error('Existen reservas sin nombres');
				errorNombres.push(true);
				return;
			}
			if (reserva.usuario.apellido === '') {
				toaster.error('Existen reservas sin apellidos');
				errorNombres.push(true);
				return;
			}
		}
	});
	return errorNombres.findIndex(valor => valor == true) === -1;
}

export const obtenerIdAreaPorNombre = (nombreArea, listaFiltroDestino) => {
	if (!nombreArea) return;
	let informacionArea = listaFiltroDestino.find(({ nombre }) => nombre.toLowerCase() == nombreArea.toLowerCase());
	return informacionArea ? informacionArea.id : null;
};

export const obtenerIdEstacionPorNombre = (nombreEstacion, listaFiltroDestino) => {
	if (!nombreEstacion) return;
	let informacionEstacion = {};
	listaFiltroDestino.forEach(area => {
		let value = area.lugaresExperiencia.find(({ nombre }) => nombre.toLowerCase() == nombreEstacion.toLowerCase());
		value && (informacionEstacion = value);
	});
	return informacionEstacion ? informacionEstacion.id : null;
};

export const obtenerIdGrupoPorNombre = (nombreGrupo, listaFiltroGrupos) => {
	if (!nombreGrupo) return;
	let informacionGrupo = listaFiltroGrupos.find(({ nombre }) => nombreGrupo == nombre);
	return informacionGrupo ? informacionGrupo.id : null;
};

export const orderViajesGrupoCatalogo = listaViajes => {
	let viajes = {};
	if (listaViajes) {
		listaViajes.map(viaje => {
			viajes[viaje.lugarExperiencia.nombreArea] = [];
		});
		listaViajes.map(viaje => {
			viajes[viaje.lugarExperiencia.nombreArea].push(viaje);
		});
		return viajes;
	}
	return [];
};
