<template>
	<div class="row d-flex flex-column flex-md-row">
		<div
			v-for="(servicios, subtipo) in serviciosBySubtipo"
			:key="subtipo"
			class="col-12 mb-3"
			:class="getClassServicio()">
			<div class="input-group gap-3 mb-1">
				<label
					v-if="categoriaServicios.categoria === 'seguro'"
					:for="'subtipo-' + subtipo"
					class="w-100 text-primary mb-2 fw-bold">
					{{ $t('servicios.seguro.subtipo.' + subtipo) }}
				</label>
				<select
					:id="'subtipo-' + subtipo"
					v-model="serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio"
					class="form-select selector-servicio text-primary translate"
					:class="{ 'rounded-end': true }"
					:disabled="!isServicioDescontratable(reserva, serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio)"
					@change="handleServicioSeleccionadoChange(subtipo)">
					<option
						v-for="servicio in servicios.filter(s => s.activo).sort((s1, s2) => s1.precio - s2.precio)"
						:key="servicio.id"
						:value="servicio"
						:disabled="!isServicioSelectable(reserva, servicio)">
						<!-- PROVISIONAL: Comentado a peticion -->
						<!-- <span v-if="!servicio.nulo && servicio.tipo !== 'seguro' && servicio.tipo !== 'festival'">
							{{ servicio.numDias }}
							<span v-if="servicio.numDias == 1"> {{ $t('general.dia') }}</span>
							<span v-else> {{ $t('general.dias') }}</span>
						</span> -->
						{{ servicio.nombre }}
						<template v-if="!servicio.nulo && subtipo !== 'consumicion' && subtipo !== 'complemento'">
							({{ $t('general.del') }}
							{{ formatDateDDmm(servicio.fechaInicio) }}
							{{ $t('general.al') }}
							{{ formatDateDDmm(servicio.fechaFin) }})
						</template>
						<template v-if="servicio.precio">
							(<template v-if="servicio.precio > 0">+</template>{{ servicio.precio }}{{ agenciaStore.getCurrency }})
						</template>
					</option>
				</select>
				<div v-if="!serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.nominal">
					<InputSelectorCantidad
						:disabled="!isServicioSelectable(reserva, serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio)"
						:count="serviciosWithCantidadBySubtipoSeleccionados[subtipo].cantidad"
						:min-count="
							isServicioDescontratable(reserva, serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio)
								? 1
								: serviciosWithCantidadBySubtipoSeleccionados[subtipo].oldCantidad
						"
						@update-count="newCount => handleCantidadServicioSeleccionadoChange(subtipo, newCount)" />
				</div>
				<div
					v-if="
						serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.nominal &&
						!serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.nulo
					" class="pt-1">
					<span class="messageIfIsNominal p-1 ms-3 rounded-pill text-primary">{{
						$t('servicios.servicioNominal')
					}}</span>
				</div>
			</div>

			<div class="row">
				<div class="row text-primary mb-2 description-text">
					<template
						v-if="
							serviciosWithCantidadBySubtipoSeleccionados[subtipo] &&
							!serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.nulo
						">
						<div class="col-8 translate">
							{{ serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.descripcion }}
						</div>
					</template>
					<div v-if="categoriaServicios.categoria === 'seguro'" class="col text-end">
						<buttonOpenPdf
							button-text="Ver Poliza"
							:id-servicio="serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.id"
							:id-viaje="viajeId"
							class="btn-poliza" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { formatDateDDmm } from '@/helpers/dateUtiles';
	import { firstLetterCapital } from '@/helpers/languageUtils';
	import { isServicioSelectable, isServicioDescontratable } from '@/helpers/serviciosUtils';
	import { reactive, onBeforeMount, computed } from 'vue';
	import buttonOpenPdf from '../../../components/buttons/buttonOpenPdf.vue';
	import { useRoute } from 'vue-router';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import InputSelectorCantidad from '@/components/inputs/InputSelectorCantidad';
	import { toRawDeep } from '@/helpers/reactivityUtils';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		categoriaServicios: {
			type: Object,
			default: null,
		},
	});

	const emits = defineEmits(['servicioSeleccionado']);

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();
	const serviciosWithCantidadBySubtipoSeleccionados = reactive({});
	const subtipos = reactive([]);
	const serviciosBySubtipo = reactive({});
	// todo: get this from pinia store
	const viajeId = useRoute().params.viajeId;

	const reserva = computed(() => storeHabitacion.getReservaByIdx(props.idxReserva));

	function addServiciosToReserva(isModificacion = false) {
		subtipos.forEach(subtipo => {
			storeHabitacion.addServicioToReserva(
				props.idxReserva,
				serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio,
				serviciosWithCantidadBySubtipoSeleccionados[subtipo].cantidad,
				isModificacion
			);
		});
		emits('servicioSeleccionado', Object.values(serviciosWithCantidadBySubtipoSeleccionados));
	}

	function handleCantidadServicioSeleccionadoChange(subtipo, newCantidad) {
		serviciosWithCantidadBySubtipoSeleccionados[subtipo].cantidad = newCantidad;
		addServiciosToReserva(true);
	}

	function handleServicioSeleccionadoChange(subtipo) {
		serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.justSelected = true;
		// Forzar cantidad 1 si es nominal
		if (serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.nominal) {
			serviciosWithCantidadBySubtipoSeleccionados[subtipo].cantidad = 1;
			serviciosWithCantidadBySubtipoSeleccionados[subtipo].oldCantidad = 1;
		}
		addServiciosToReserva(true);
	}

	function getClassServicio() {
		if (serviciosBySubtipo) {
			if (Object.keys(serviciosBySubtipo).length === 1) {
				return 'col-md-12';
			} else if (Object.keys(serviciosBySubtipo).length === 2) {
				return 'col-md-6';
			}
			return 'col-md-4';
		}
	}

	function init() {
		// All the subtipos are gathered
		props.categoriaServicios.servicios.map(({ subtipo }) => {
			if (subtipos.indexOf(subtipo) === -1) {
				subtipos.push(subtipo);
			}
		});

		// The servicios are gathered by subtipo
		subtipos.forEach(subtipo => {
			serviciosBySubtipo[subtipo] = props.categoriaServicios.servicios.filter(servicio => subtipo === servicio.subtipo);
			const categoriaSubtipo = `${props.categoriaServicios.categoria}${firstLetterCapital(subtipo)}`;
			const serviciosSubtipo = serviciosBySubtipo[subtipo];
			const reservaCurrentServicioWithCantidad = reserva.value.serviciosWithCantidad[categoriaSubtipo];
			const servicioPB = serviciosSubtipo.find(({ paqueteBasico }) => paqueteBasico) || null;
			// If the booking does not have any service of this category needs to be initialised
			if (reservaCurrentServicioWithCantidad === undefined) {
				if (servicioPB !== null) {
					serviciosWithCantidadBySubtipoSeleccionados[subtipo] = {
						servicio: servicioPB,
						cantidad: 1,
						oldCantidad: 1,
						categoriaPaqueteBasico: true,
						servicioPB: servicioPB,
					};
				} else {
					serviciosWithCantidadBySubtipoSeleccionados[subtipo] = {
						servicio: serviciosSubtipo.find(({ nulo }) => nulo),
						cantidad: 1,
						oldCantidad: 1,
						categoriaPaqueteBasico: false,
						servicioPB: null,
					};
				}
			} else {
				// Otherwise the current value is load
				serviciosWithCantidadBySubtipoSeleccionados[subtipo] = toRawDeep(reservaCurrentServicioWithCantidad);
				serviciosWithCantidadBySubtipoSeleccionados[subtipo].oldCantidad =
					serviciosWithCantidadBySubtipoSeleccionados[subtipo].cantidad;
				serviciosWithCantidadBySubtipoSeleccionados[subtipo].categoriaPaqueteBasico = servicioPB != null;
				serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicioPB = servicioPB;
			}
		});
		addServiciosToReserva();
	}

	onBeforeMount(() => {
		init();
	});
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;

			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}

	.selector-servicio {
		background-color: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
	}

	.btn-poliza {
		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}

	.description-text {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0.4rem;

		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}

	.selector-servicio.rounded-end {
		border-top-right-radius: var(--bs-border-radius) !important;
		border-bottom-right-radius: var(--bs-border-radius) !important;
	}

	.messageIfIsNominal {
		background-color: rgba(var(--bs-secondary-rgb), 0.35);
		font-size: 0.6rem;
	}
</style>
