<template>
	<div class="row">
		<div class="col">
			<div class="input-group gap-3 mb-1">
				<select
					v-model="servicioWithCantidadSeleccionado.servicio"
					class="form-select selector-servicio text-primary translate"
					:class="{ 'rounded-end': true }"
					:disabled="!isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)"
					@change="handleServicioSeleccionadoChange">
					<option
						v-for="servicio in categoriaServicios.servicios.filter(s => s.activo).sort((s1, s2) => s1.precio - s2.precio)"
						:key="servicio.id"
						:value="servicio"
						:disabled="!isServicioSelectable(reserva, servicio)">
						<!-- PROVISIONAL: Comentado a peticion -->
						<!-- <span v-if="!servicio.nulo && servicio.tipo === 'transporte'">
							{{ servicio.numDias }}
							<span v-if="servicio.numDias == 1"> {{ $t('general.dia') }}</span>
							<span v-else> {{ $t('general.dias') }}</span>
						</span> -->
						{{ servicio.nombre }}
						<template v-if="!servicio.nulo">
							({{ $t('general.del') }}
							{{ formatDateDDmm(servicio.fechaInicio) }}
							{{ $t('general.al') }} {{ formatDateDDmm(servicio.fechaFin) }})
						</template>
						<template v-if="servicio.precio">
							(<template v-if="servicio.precio > 0">+</template>{{ servicio.precio }}{{ agenciaStore.getCurrency }})
						</template>
					</option>
				</select>
				<div v-if="!servicioWithCantidadSeleccionado.servicio.nominal">
					<InputSelectorCantidad
						:disabled="!isServicioSelectable(reserva, servicioWithCantidadSeleccionado.servicio)"
						:count="servicioWithCantidadSeleccionado.cantidad"
						:min-count="
							isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)
								? 1
								: servicioWithCantidadSeleccionado.oldCantidad
						"
						@update-count="handleCantidadServicioSeleccionadoChange" />
				</div>
				<div
					v-else-if="
						servicioWithCantidadSeleccionado.servicio.nominal && !servicioWithCantidadSeleccionado.servicio.nulo
					" class="pt-1">
					<span class="text-if-nominal p-1 ms-3 rounded-pill text-primary">{{ $t('servicios.servicioNominal') }}</span>
				</div>
			</div>
			<div class="row text-primary mb-2 description-text">
				<template v-if="servicioWithCantidadSeleccionado && !servicioWithCantidadSeleccionado.servicio.nulo">
					<div class="col-12 translate">
						{{ servicioWithCantidadSeleccionado.servicio.descripcion }}
					</div></template
				>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { formatDateDDmm } from '@/helpers/dateUtiles';
	import { isServicioSelectable, isServicioDescontratable } from '@/helpers/serviciosUtils';
	import { onBeforeMount, ref, computed } from 'vue';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import InputSelectorCantidad from '@/components/inputs/InputSelectorCantidad';
	import { toRawDeep } from '@/helpers/reactivityUtils';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		categoriaServicios: {
			type: Object,
			default: null,
		},
	});

	const emits = defineEmits(['servicioSeleccionado']);

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();

	const servicioWithCantidadSeleccionado = ref();

	const reserva = computed(() => storeHabitacion.getReservaByIdx(props.idxReserva));

	function addServicioToReserva(isModificacion = false) {
		storeHabitacion.addServicioToReserva(
			props.idxReserva,
			servicioWithCantidadSeleccionado.value.servicio,
			servicioWithCantidadSeleccionado.value.cantidad,
			isModificacion
		);
		emits('servicioSeleccionado', servicioWithCantidadSeleccionado.value);
	}

	function handleCantidadServicioSeleccionadoChange(newCantidad) {
		servicioWithCantidadSeleccionado.value.cantidad = newCantidad;
		addServicioToReserva(true);
	}

	function handleServicioSeleccionadoChange() {
		servicioWithCantidadSeleccionado.value.servicio.justSelected = true;
		if (servicioWithCantidadSeleccionado.value.servicio.nulo) {
			servicioWithCantidadSeleccionado.value.cantidad = 1;
			servicioWithCantidadSeleccionado.value.oldCantidad = 1;
		}
		addServicioToReserva(true);
	}

	function init() {
		const categoria = props.categoriaServicios.categoria;
		const reservaCurrentServicioWithCantidad = reserva.value.serviciosWithCantidad[categoria];
		const servicioPB = props.categoriaServicios.servicios.find(({ paqueteBasico }) => paqueteBasico) || null;
		// If the booking does not have any service of this category needs to be initialised
		if (reservaCurrentServicioWithCantidad === undefined) {
			if (servicioPB !== null) {
				servicioWithCantidadSeleccionado.value = {
					servicio: servicioPB,
					cantidad: 1,
					oldCantidad: 1,
					categoriaPaqueteBasico: true,
					servicioPB: servicioPB,
				};
			} else {
				servicioWithCantidadSeleccionado.value = {
					servicio: props.categoriaServicios.servicios.find(({ nulo }) => nulo),
					cantidad: 1,
					oldCantidad: 1,
					categoriaPaqueteBasico: false,
					servicioPB: null,
				};
			}
		} else {
			// Otherwise the current value is load
			servicioWithCantidadSeleccionado.value = toRawDeep(reservaCurrentServicioWithCantidad);
			servicioWithCantidadSeleccionado.value.oldCantidad = servicioWithCantidadSeleccionado.value.cantidad;
			servicioWithCantidadSeleccionado.value.categoriaPaqueteBasico = servicioPB !== null;
			servicioWithCantidadSeleccionado.value.servicioPB = servicioPB;
		}
		addServicioToReserva();
	}

	onBeforeMount(() => {
		init();
	});
</script>

<style lang="scss" scoped>
	.selector-servicio {
		background-color: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
	}

	.description-text {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0.4rem;

		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}

	.text-if-nominal {
		background-color: rgba(var(--bs-secondary-rgb), 0.35);
		font-size: 0.6rem;
	}
</style>
