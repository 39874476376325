<template>
	<div v-if="motivosCancelacion">
		<p class="subTitle-cancelacion text-primary pb-3">
			{{ $t('reserva.msjSolucionReserva') }}
		</p>

		<div v-for="motivo in motivos" :key="motivo.id" class="form-check d-flex flex-row-reverse p-0">
			<input
				:id="'radio' + motivo.id"
				v-model="motivoSeleccionado"
				:value="motivo"
				class="form-check-input"
				type="radio"
				name="flexRadioDefault" />
			<label class="form-check-label w-100" :for="'radio' + motivo.id">
				{{ motivo.motivo }}
			</label>
		</div>
		<div v-if="motivoSeleccionado" class="mt-3">
			<p class="fw-bold mb-0">
				{{ $t('general.solucion') }}
			</p>
			<p>
				{{ motivoSeleccionado.solucion }}
			</p>
		</div>
	</div>

	<div v-else>
		<div
			v-for="reserva in reservasCancelar"
			:key="reserva.id"
			class="w-100 text-white fw-normal justify-content-between my-4 mx-0">
			<TarjetaProximaReservaAcciones
				:reserva="reserva"
				:grupo="grupo"
				:unique="reservasCancelar.length == 1"
				action="Cancel" />
		</div>

		<div class="text-primary text-bold row">
			<div class="col text-primary text-title">{{ $t('home.form.policy.titleCondicionesCancelacion') }}</div>
			<div class="col-3 text-end" @click="toggleCollapse()">
				<button class="btn bg-transparent border-0">
					<i class="fa-solid fa-chevron-up" :class="isCollapsed ? 'icon-active' : 'icon-inactive'" />
				</button>
			</div>
		</div>

		<transition name="collapse">
			<div v-show="!isCollapsed" class="collapse-content">
				<template v-if="!grupo.buscador">
					<PoliticasCancelacion
						:politica-cancelacion="grupo.politicaCancelacion"
						:fecha-limite-pago="fechaLimitePago"
						:precio-total="grupo.reservas[0].precioTotal" />
				</template>
				<template v-else>
					<h5 class="text-secondary fw-bold">
						{{ $t('home.form.policy.cancelReserva') }}
					</h5>
					<span v-sanitize-html="politicasCancelacion" />

					<p>
						{{ $t('home.form.policy.detail4Condiciones') }}
					</p>
					<p>* {{ $t('home.form.policy.detail5Condiciones') }}</p>
				</template>
			</div>
		</transition>
	</div>

	<div class="text-center">
		<template v-if="motivosCancelacion">
			<button type="button" class="btn btn-link" @click="closeModal()">
				{{ $t('reserva.mantenerReserva') }}
			</button>
			<button type="button" class="btn btn-primary" @click="cancelarReservaConfirmacion()">
				{{ $t('botones.cancelarReserva') }}
			</button>
		</template>
		<template v-else>
			<button type="button" class="btn btn-link" @click="realizarCancelaciones()">
				{{ $t('botones.cancelarReserva') }}
			</button>
			<button type="button" class="btn btn-primary" @click="closeModal()">
				{{ $t('botones.descartar') }}
			</button>
		</template>
	</div>
</template>

<script setup>
	import { onMounted, ref, watch } from 'vue';
	import TarjetaProximaReservaAcciones from '../tarjetas/TarjetaProximaReservaAcciones.vue';
	import PoliticasCancelacion from '@/components/misc/PoliticasCancelacion.vue';
	import { useI18n } from 'vue-i18n';
	import { obtenerPoliticasCancelacion } from '@/helpers/htmlMakers';
	import { alertAutoCloseSuccessWithoutCallback } from '@/services/utils/AlertUtils';
	import { createToaster } from '@meforma/vue-toaster';
	import ReservasApiService from 'src/services/api/skiandnight/ReservasApiService';
	import { HITOS_PAGO_TIPOCANTIDAD } from '@/constants/viajeConstants';
	import { ESTADOS_RESERVA } from '@/constants/reservaConstants';

	const props = defineProps({
		grupo: {
			type: Object,
			required: true,
		},
	});

	const emit = defineEmits(['motivosCancelacion', 'close']);

	const politicasCancelacion = ref('');
	const listaReservasCancelacion = ref([]);
	const motivoSeleccionado = ref(null);
	const motivosCancelacion = ref(false);
	const isCollapsed = ref(true);
	const reservasCancelar = ref(props.grupo.reservas);
	const motivos = ref([]);

	const { locale } = useI18n();

	const toaster = createToaster({ position: 'top-right' });

	watch(locale, () => {
		actualizarPoliticasCancelacion();
	});

	watch(motivosCancelacion, () => {
		emit('motivosCancelacion', motivosCancelacion.value);
	});

	function fechaLimitePago() {
		return props.grupo.viaje.hitosPago.find(hito => hito.tipoCantidad === HITOS_PAGO_TIPOCANTIDAD.RESTANTE)?.fecha;
	}

	function actualizarPoliticasCancelacion() {
		politicasCancelacion.value = obtenerPoliticasCancelacion(props.grupo, false);
	}

	function toggleCollapse() {
		isCollapsed.value = !isCollapsed.value;
	}

	function realizarCancelaciones() {
		listaReservasCancelacion.value = props.grupo.reservas.map(reserva => reserva.id);
		if (listaReservasCancelacion.value.length === 0) {
			toaster.error('Selecciona alguna reserva para continuar');
		} else {
			motivosCancelacion.value = true;
		}
	}

	function closeModal() {
		listaReservasCancelacion.value = [];
		motivosCancelacion.value = false;
		emit('close');
	}

	function cancelarReservaConfirmacion() {
		if (!motivoSeleccionado.value) {
			toaster.error('Selecciona algun motivo para continuar');
			return;
		}

		ReservasApiService.doCancelarReservasGrupo(
			props.grupo.id,
			listaReservasCancelacion.value,
			motivoSeleccionado.value.id.toString(10),
			'123123'
		).then(() => {
			listaReservasCancelacion.value.map(reserva => {
				let indexReserva = reservasCancelar.value.findIndex(val => val.id === reserva);
				if (indexReserva !== -1) {
					reservasCancelar.value[indexReserva].estado = ESTADOS_RESERVA.CANCELADA;
				}
			});
			alertAutoCloseSuccessWithoutCallback();
			emit('close');
			window.location.reload();
		});
	}

	onMounted(() => {
		actualizarPoliticasCancelacion();
		ReservasApiService.getMotivosCancelacion().then(res => (motivos.value = res));
	});
</script>

<style lang="css" scoped>
	.form-check-input {
		width: 1.5em;
		height: 1.5em;
	}

	.form-check-input:checked {
		background-color: var(--bs-secondary);
		border-color: var(--bs-secondary);
	}

	.btn-link {
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		padding: 8px 20px;
	}

	.title-cancelacion {
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.206px;
	}

	.subTitle-cancelacion {
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.154px;
	}
	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.text-title {
		font-size: 16px;
		font-weight: 700;
	}

	.btn-primary {
		font-size: 14px;
		font-weight: 500;
	}
</style>
