export const capitalize = string => {
	return string && string.toUpperCase();
};

export const capitalizeFirstLetter = string => {
	let s = string.toLowerCase();
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const titleCase = string => {
	var splitStr = string.split(' ');
	for (var i = 0; i < splitStr.length; i++) {
		splitStr[i] = capitalizeFirstLetter(splitStr[i]);
	}
	return splitStr.join(' ');
};

export const showOnlyBlockOfText = (text, limit = 200) => {
	if (text == null) return;

	text = text.substring(0, limit);
	return text + '....';
};

export const showOnlyTwoLines = text => {
	if (text == null || text.length < 170) return text;

	text = text.substring(0, 170);
	return text + '....';
};
