<template>
	<div class="container-view">
		<div class="col-12 mb-3 fw-bold atras">
			<div @click="$router.go(-1)">&lt;{{ $t('home.form.backButton') }}</div>
		</div>

		<ul class="nav nav-tabs">
			<li class="nav-item fs-5 text-primary fw-bold mb-4" @click="activeView = 'checkin'">
				<a class="nav-link" :class="activeView == 'checkin' ? 'active' : ''" aria-current="page">
					{{ $t('general.checkInOnline') }}
				</a>
			</li>
			<li class="nav-item fs-5 text-primary fw-bold mb-4" @click="activeView = 'informe'">
				<a
					class="nav-link"
					aria-current="page"
					:class="
						activeView == 'informe' ? 'active' : '' || informacionHabitacion.informeTotalesHash == null ? 'd-none' : ''
					">
					{{ $t('reserva.informesTotales') }}:
				</a>
			</li>
			<li class="nav-item fs-5 text-primary fw-bold mb-4" @click="activeView = 'cambio'">
				<a
					class="nav-link"
					aria-current="page"
					:class="
						activeView == 'cambio'
							? 'active'
							: '' || informacionHabitacion.grupoChanged == null
							? 'd-none'
							: informacionHabitacion.grupoChanged
							? 'text-danger'
							: ''
					">
					{{ $t('reserva.cambioHabitacion') }}:
				</a>
			</li>
		</ul>

		<Spinner v-if="loading" />

		<template v-if="error">
			<div>{{ $t('errors.cargaInformacion') }}</div>
		</template>

		<template v-if="!error && !loading">
			<template v-if="activeView === 'checkin'">
				<div class="mt-3">
					<div class="fs-5 text-primary fw-bold">
						<p>
							<span class="fw-bold">{{ $t('general.viaje').toLocaleUpperCase() }}:</span>
							<span class="ps-1">
								{{ informacionHabitacion.lugarExperiencia }}
								{{ formatDateDDmmmYYYY(informacionHabitacion.fechaViajeSalida) }}
								{{ $t('general.al') }}
								{{ formatDateDDmmmYYYY(informacionHabitacion.fechaViajeLLegada) }}
							</span>
						</p>
						<p>
							<span class="fw-bold">{{ $t('general.grupo').toUpperCase() }}:</span>
							<span class="ps-1">{{ informacionHabitacion.nombreGrupo }}</span>
						</p>
						<p>
							<span class="fw-bold">{{ $t('alojamiento.nombre').toLocaleUpperCase() }}:</span>
							<span class="ps-1">
								{{ informacionHabitacion.nombreAlojamiento }}
							</span>
						</p>
					</div>

					<p class="text-muted fw-bold" style="font-size: 0.8rem">
						{{ $t('reserva.msjCheckInReserva') }}
					</p>

					<div v-if="reservas" id="accordionExample" class="accordion">
						<div v-for="reserva in reservas" :key="reserva.id" class="accordion-item">
							<h2 :id="'reserva' + reserva.id" class="accordion-header">
								<!--TODO AGREGAR FORMATO DE TEXTO A CHECKIN REALIZADO-->
								<button
									class="accordion-button bg-primary text-white"
									type="button"
									data-bs-toggle="collapse"
									:data-bs-target="'#collapse' + reserva.id"
									aria-expanded="true"
									:aria-controls="'collapse' + reserva.id"
									:disabled="!reserva.checkInPendiente && reserva.dniSubido">
									{{ reserva.nombre }}
									<template v-if="!reserva.checkInPendiente"> ({{ $t('general.checkInRealizado') }}) </template>
								</button>
							</h2>
							<div
								:id="'collapse' + reserva.id"
								class="accordion-collapse collapse"
								:class="{
									show: !((!reserva.checkInPendiente && reserva.dniSubido) || reserva.hasPagosPendientes),
								}"
								:aria-labelledby="'reserva' + reserva.id"
								data-bs-parent="#accordionExample">
								<div class="accordion-body">
									<CheckIn
										:reserva="reserva"
										:fecha-inicio="informacionHabitacion.fechaViajeSalida"
										:fecha-fin="informacionHabitacion.fechaViajeLLegada"
										@check-realizado="handleRealizado(reserva.id)" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template v-if="activeView === 'cambio'">
				<div>
					<p v-if="informacionHabitacion.grupoChanged != null" class="mt-4">
						<span class="fw-bold">{{ $t('reserva.cambioHabitacion') }}:</span>
						<span v-if="informacionHabitacion.grupoChanged">{{ $t('general.si') }}</span>
						<span v-else>{{ $t('general.no') }}</span>
					</p>
					<p v-for="r in reservas" :key="r.id">
						{{ r.nombre }} :
						{{ getIdsServiciosNoNulos(r.servicios) }}
					</p>

					<p class="fs-5 fw-bold">
						{{ $t('reserva.descargar') }}
					</p>

					<p v-for="servicio in getListaServicios(reservas)" :key="servicio.id">
						({{ servicio.id }} )
						<span v-if="servicio.tipo !== 'festival'">
							{{ servicio.numDias }} <span v-if="servicio.numDias === 1"> {{ $t('general.dia') }}</span>
							<span v-else> {{ $t('general.dias') }}</span>
						</span>
						{{ servicio.nombreCompleto }} {{ $t('general.del') }} {{ servicio.fechaInicio }} {{ $t('general.al') }}
						{{ servicio.fechaFin }}
						<span class="fw-bold">: {{ servicio.cantidad }}</span>
					</p>
				</div>
			</template>

			<template v-if="activeView === 'informe'">
				<div>
					<div v-if="informacionHabitacion.informeTotalesHash != null">
						<a :href="baseURL" target="_blank" class="">
							<button class="btn btn-secondary mt-4">{{ $t('botones.descargar') }}</button>
						</a>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script setup lan="ts">
	import ReservasApiService from '@/services/api/skiandnight/ReservasApiService';
	import CheckIn from './CheckIn.vue';
	import Spinner from '../../components/SpinnerLoading.vue';
	import { formatDateDDmmmYYYY } from '@/helpers/dateUtiles';
	import { onBeforeMount, reactive, ref } from 'vue';
	import { useRoute } from 'vue-router';

	const route = useRoute();
	const loading = ref(true);
	const error = ref(null);
	const activeView = ref('checkin');
	const reservas = ref([]);
	const baseURL = ref('');

	const informacionHabitacion = reactive({
		lugarExperiencia: ' ',
		fechaViajeSalida: '',
		fechaViajeLLegada: '',
		nombreGrupo: '',
		nombreAlojamiento: '',
	});

	function handleRealizado(reservaId) {
		let indexReserva = reservas.value.findIndex(({ id }) => id === reservaId);
		reservas.value[indexReserva].checkInPendiente = false;
		getInformacionCheckin();
	}

	function getIdsServiciosNoNulos(listaServicios) {
		return listaServicios.filter(servicio => !servicio.nulo).map(servicio => servicio.id);
	}

	function getListaServicios(reservas) {
		let listaServicios = [];

		reservas.forEach(reserva => {
			reserva.serviciosWithCantidad.forEach(swc => {
				const idx = listaServicios.findIndex(s => s.id === swc.servicio.id);
				if (idx !== -1) {
					listaServicios[idx]['cantidad'] += swc.cantidad;
				} else {
					!swc.servicio.nulo && listaServicios.push({ ...swc.servicio, cantidad: swc.cantidad });
				}
			});
		});

		return listaServicios;
	}

	async function getInformacionCheckin() {
		try {
			const res = await ReservasApiService.getInformacionCheckIn(route.params.grupoId, route.params.reservaId);
			reservas.value = res.reservas;
			informacionHabitacion.grupoChanged = res.grupoChanged;
			informacionHabitacion.lugarExperiencia = res.lugarExperiencia;
			informacionHabitacion.fechaViajeSalida = res.fechaInicio;
			informacionHabitacion.fechaViajeLLegada = res.fechaFin;
			informacionHabitacion.nombreAlojamiento = res.alojamiento;
			informacionHabitacion.nombreGrupo = res.grupo;
			informacionHabitacion.informeTotalesHash = res.informeTotalesHash;
			baseURL.value =
				process.env.VUE_APP_SKI_AND_NIGHT_BASE_URL +
				'/api/v1/grupos/' +
				route.params.grupoId +
				'/informe/' +
				res.informeTotalesHash;
		} catch (e) {
			console.error(e);
			error.value = true;
		} finally {
			loading.value = false;
		}
	}

	onBeforeMount(() => getInformacionCheckin());
</script>

<style scoped>
	.container-view {
		max-width: 800px;
		margin: auto;
		padding-top: 15rem;
		min-height: 80vh;
	}

	.accordion-header {
		overflow: hidden;
		border-radius: var(--bs-border-radius-xl);
		border: none;
	}

	.accordion-item {
		margin-bottom: 1rem;
		border-radius: var(--bs-border-radius-xl);
		overflow: hidden;
		border: none;
	}

	.accordion-body {
		background-color: #f9f7f8;
		margin-top: 1rem;
		border-radius: var(--bs-border-radius-xl);
	}

	.atras {
		cursor: pointer;
	}

	.nav-item {
		cursor: pointer;
	}

	@media screen and (max-width: 1021px) {
		.container-view {
			max-width: 800px;
			margin: auto;
			padding: 7rem 1rem 0 1rem;
		}
	}
</style>
