<template>
	<div
		id="buscador-para-ti"
		class="container buscador text-start justify-content-center"
		:class="landing ? 'width-buscador-sin-forfait' : 'width-buscador-con-forfait'">
		<div class="row justify-content-between align-items-center w-100">
			<div class="buscador-col section-search p-0" :class="landing ? 'col-3' : 'col-2'">
				<div class="dropdown">
					<div id="destiny" class="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<div class="buscador-titulo" :class="errors.destino ? 'error' : ''">
							{{ $t('searcher.destiny.title') }}
						</div>
						<div class="buscador-subtitulo text-muted" :class="errors.destino ? 'error' : ''">
							<template v-if="filtrosSeleccionadosBuscador.destino.nombre">
								{{ titleCase(filtrosSeleccionadosBuscador.destino.nombre) }}
							</template>
							<template v-else>
								{{ $t('searcher.destiny.subtitle') }}
							</template>
						</div>
					</div>
					<ul class="dropdown-menu dropdown-destino px-4" aria-labelledby="destiny">
						<div v-if="filtrosBuscador" class="row justify-content-start py-2">
							<div v-for="(area, index) in filtrosBuscador.areas" :key="index" class="col-3">
								<div class="fw-bold text-primary">
									{{ titleCase(area.nombre) }}
								</div>
								<div v-for="lugarExperiencia in area.lugaresExperiencia" :key="lugarExperiencia.nombre" role="button">
									<div
										class="buscador-subtitulo text-clickable"
										@click="setDestino(lugarExperiencia.nombre, lugarExperiencia.id)">
										{{ titleCase(lugarExperiencia.nombre) }}
									</div>
								</div>
							</div>
						</div>
					</ul>
				</div>
			</div>

			<div
				class="buscador-col section-search"
				:class="[landing ? 'col-4 px-0' : showForfaitSection ? 'col-3 ps-3' : 'col-4 ps-3']">
				<div id="datesAccomodation" class="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
					<div class="buscador-titulo" :class="errors.fechasAlojamiento ? 'error' : ''">
						{{ $t('searcher.datesAccomodation.title') }}
					</div>
					<div class="buscador-subtitulo text-muted" :class="errors.fechasAlojamiento ? 'error' : ''">
						<template v-if="filtrosSeleccionadosBuscador.fechasAlojamiento">
							{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasAlojamiento[0]) }}
							-
							{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasAlojamiento[1]) }}
						</template>
						<template v-else>
							{{ $t('searcher.datesAccomodation.subtitle') }}
						</template>
					</div>
				</div>
				<ul class="dropdown-menu p-3" aria-labelledby="datesAccomodation">
					<div class="position-relativa">
						<Datepicker
							v-model="filtrosSeleccionadosBuscador.fechasAlojamiento"
							locale="es"
							inline
							range
							multi-calendars
							auto-apply
							:min-date="tomorrowAux" />
					</div>
				</ul>
			</div>

			<div v-if="showForfaitSection" class="col-3 buscador-col section-search ps-3">
				<div id="datesForfait" class="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
					<div class="buscador-titulo" :class="errors.fechasForfait ? 'error' : ''">
						{{ $t('searcher.datesForfait.title') }}
					</div>
					<div class="buscador-subtitulo" :class="errors.fechasForfait ? 'error' : ''">
						<span v-if="filtrosSeleccionadosBuscador.fechasForfait" class="text-muted">
							{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasForfait[0]) }}
							-
							{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasForfait[1]) }}
						</span>
						<span v-else class="text-muted">
							{{ $t('searcher.datesForfait.subtitle') }}
						</span>
					</div>
				</div>
				<ul class="dropdown-menu p-3" aria-labelledby="datesForfait">
					<div>
						<Datepicker
							v-model="filtrosSeleccionadosBuscador.fechasForfait"
							:min-date="new Date(filtrosSeleccionadosBuscador.fechasAlojamiento[0])"
							:max-date="new Date(filtrosSeleccionadosBuscador.fechasAlojamiento[1])"
							locale="es"
							inline
							range
							multi-calendars
							auto-apply />
					</div>
				</ul>
			</div>

			<div class="buscador-col p-0" :class="landing ? 'col-3 p-0' : 'col-2 ps-3'">
				<div
					id="rooms"
					class=""
					role="button"
					aria-expanded="false"
					data-bs-toggle="modal"
					data-bs-target="#habitacionesBuscadorModal">
					<div class="buscador-titulo text-primary" :class="errors.habitaciones ? 'error' : ''">
						<span v-if="filtrosSeleccionadosBuscador.habitaciones">
							{{ filtrosSeleccionadosBuscador.habitaciones.length }}
						</span>
						{{ $t('searcher.rooms.title') }}
					</div>
					<div class="buscador-subtitulo text-muted" :class="errors.habitaciones ? 'error' : ''">
						<template v-if="filtrosSeleccionadosBuscador.habitaciones">
							{{ cantidadPersonas.adultos }}
							{{ $t('general.personas.plural.adulto') }} ,
							{{ cantidadPersonas.ninos }}
							{{ $t('general.personas.plural.nino') }}
						</template>
						<template v-else>
							{{ $t('searcher.rooms.subtitle') }}
						</template>
					</div>
				</div>
			</div>

			<div class="col-auto buscador-col" :class="landing ? 'px-2' : 'ps-4'" @click="goToViajesAMedida()">
				<div class="goTo lupa hvr-grow pr-0">
					<i class="fa-solid fa-magnifying-glass fa-2x text-primary bg-secondary p-3 rounded-circle pointer" />
				</div>
			</div>
		</div>
	</div>

	<div class="container mobile-buscador text-start justify-content-center box-shadow-buscador">
		<div class="mobile-buscador">
			<buscador-input :has-error="errors.destino" @click="showDestinosModal = true">
				<template #label>
					{{ $t('searcher.destiny.title') }}
				</template>
				<template #content>
					<template v-if="filtrosSeleccionadosBuscador.destino.nombre">
						{{ titleCase(filtrosSeleccionadosBuscador.destino.nombre) }}
					</template>
					<template v-else>
						{{ $t('searcher.destiny.subtitle') }}
					</template>
				</template>
			</buscador-input>

			<buscador-input :has-error="errors.fechasAlojamiento" @click="showFechasAlojamientoModal = true">
				<template #label>
					{{ $t('searcher.datesAccomodation.title') }}
				</template>
				<template #content>
					<template v-if="filtrosSeleccionadosBuscador.fechasAlojamiento">
						{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasAlojamiento[0]) }}
						-
						{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasAlojamiento[1]) }}
					</template>
					<template v-else>
						{{ $t('searcher.datesAccomodation.subtitle') }}
					</template>
				</template>
			</buscador-input>

			<buscador-input
				v-if="showForfaitSection"
				:has-error="errors.fechasForfait"
				@click="showFechasForfaitModal = true">
				<template #label>
					{{ $t('searcher.datesForfait.title') }}
				</template>
				<template #content>
					<template v-if="filtrosSeleccionadosBuscador.fechasForfait">
						{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasForfait[0]) }}
						-
						{{ formatDateWithWeekDay(filtrosSeleccionadosBuscador.fechasForfait[1]) }}
					</template>
					<template v-else>
						{{ $t('searcher.datesForfait.subtitle') }}
					</template>
				</template>
			</buscador-input>

			<buscador-input :has-error="errors.habitaciones" @click="showHabitacionesModal = true">
				<template #label>
					<span v-if="filtrosSeleccionadosBuscador.habitaciones">
						{{ filtrosSeleccionadosBuscador.habitaciones.length }}
					</span>

					{{
						filtrosSeleccionadosBuscador.habitaciones?.length === 1
							? $t('alojamiento.habitaciones.singular')
							: $t('alojamiento.habitaciones.plural')
					}}
				</template>
				<template #content>
					<template v-if="filtrosSeleccionadosBuscador.habitaciones">
						{{ cantidadPersonas.adultos }}
						{{ $t(`general.personas.${cantidadPersonas.adultos === 1 ? 'singular.adulto' : 'plural.adulto'}`)
						}}<!--
            --><template v-if="cantidadPersonas.ninos > 0"
							>, {{ cantidadPersonas.ninos }}
							{{ $t(`general.personas.${cantidadPersonas.ninos === 1 ? 'singular.nino' : 'plural.nino'}`) }}
						</template>
					</template>
					<template v-else>
						{{ $t('searcher.rooms.subtitle') }}
					</template>
				</template>
			</buscador-input>

			<div class="input-group d-flex flex-column align-items-center">
				<button class="btn btn-mobile btn-primary w-50" data-bs-dismiss="modal" @click="goToViajesAMedida">
					{{ $t('general.buscar') }}
				</button>
			</div>
		</div>
	</div>
	<div
		id="habitacionesBuscadorModal"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="habitacionesBuscadorModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body">
					<ModalHabitacionesBuscador @habitaciones="setHabitaciones" />
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-primary d-none d-lg-block"
						data-bs-dismiss="modal"
						@click="validateHabitaciones()">
						{{ $t('botones.aceptar') }}
					</button>
					<button
						type="button"
						class="btn btn-primary d-block d-lg-none"
						data-bs-dismiss="modal"
						@click="validateHabitaciones()">
						{{ $t('botones.aceptar') }}
					</button>
				</div>
			</div>
		</div>
	</div>
	<modal-base
		:show="showDestinosModal"
		:title="$t('searcher.destiny.title')"
		:hide-submit="true"
		:hide-cancel="true"
		@on-close="closeDestinosModal">
		<template #body>
			<div v-if="filtrosBuscador" class="column justify-content-around py-2">
				<div v-for="(area, index) in filtrosBuscador.areas" :key="index">
					<div class="fw-bold text-primary">
						{{ titleCase(area.nombre) }}
					</div>
					<div v-for="lugarExperiencia in area.lugaresExperiencia" :key="lugarExperiencia.nombre" role="button">
						<div
							:id="lugarExperiencia.id"
							class="buscador-subtitulo p-1 text-clickable"
							@click="setDestinoAndClose(lugarExperiencia.nombre, lugarExperiencia.id)">
							{{ titleCase(lugarExperiencia.nombre) }}
						</div>
					</div>
				</div>
			</div>
		</template>
	</modal-base>

	<modal-base
		:show="showFechasAlojamientoModal"
		:title="$t('searcher.datesAccomodation.title')"
		:hide-cancel="true"
		@on-close="closeFechasAlojamientoModal"
		@on-submit="acceptFechasAlojamiento">
		<template #body>
			<div class="d-flex justify-content-center p-0">
				<Datepicker
					v-model="filtrosSeleccionadosBuscador.fechasAlojamiento"
					locale="es"
					inline
					range
					multi-calendars
					auto-apply
					:min-date="tomorrowAux" />
			</div>
		</template>
	</modal-base>

	<modal-base
		:show="showFechasForfaitModal"
		:title="$t('searcher.datesForfait.title')"
		:hide-cancel="true"
		@on-close="closeFechasForfaitModal"
		@on-submit="acceptFechasForfait">
		<template #body>
			<div class="d-flex justify-content-center p-0">
				<Datepicker
					v-model="filtrosSeleccionadosBuscador.fechasForfait"
					locale="es"
					inline
					range
					multi-calendars
					auto-apply
					:min-date="tomorrowAux" />
			</div>
		</template>
	</modal-base>

	<modal-base
		:show="showHabitacionesModal"
		:title="$t('searcher.rooms.title')"
		:submit-text="$t('botones.aceptar')"
		@on-close="closeHabitacionesModal"
		@on-submit="acceptHabitaciones">
		<template #body>
			<ModalHabitacionesBuscador @habitaciones="setHabitaciones" />
		</template>
	</modal-base>
</template>

<script setup>
	import Datepicker from '@vuepic/vue-datepicker';
	import { ref, computed, watch, onMounted } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import { Buscador } from '@/router/RouterNames';
	import { buscadorStore } from '@/store_pinia/buscador';
	import { formatDateWithWeekDay } from '@/helpers/dateUtiles';
	import { titleCase } from '@/helpers/filters';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import ModalHabitacionesBuscador from '../modales/ModalHabitacionesBuscador.vue';
	import ModalBase from '@/components/modales/ModalBase.vue';
	import BuscadorInput from '@/components/buscadores/BuscadorInput.vue';

	const store_buscador = buscadorStore();
	const agenciaStore = agenciaStoreModule();

	const route = useRoute();
	const showDestinosModal = ref(false);
	const showFechasAlojamientoModal = ref(false);
	const showFechasForfaitModal = ref(false);
	const showHabitacionesModal = ref(false);
	const router = useRouter();
	const today = new Date();
	const tomorrowAux = new Date();
	tomorrowAux.setDate(today.getDate() + 1);

	const errors = ref({
		destino: false,
		fechasAlojamiento: false,
		fechasForfait: false,
		habitaciones: false,
	});

	const filtrosSeleccionadosBuscador = computed(() => store_buscador.filtrosSeleccionadosBuscador);

	const fechaALojamiento = computed(() => filtrosSeleccionadosBuscador.value.fechasAlojamiento);

	const filtrosBuscador = computed(() => store_buscador.filtrosBuscador);

	const landing = computed(() => route.path === '/');

	const showForfaitSection = computed(() => !landing.value && agenciaStore.buscadorForfaitEnabled);

	const cantidadPersonas = computed(() => {
		let cantidad = {
			ninos: 0,
			adultos: 0,
		};
		store_buscador.filtrosSeleccionadosBuscador.habitaciones.map(({ habitacion }) => {
			habitacion.map(edad => {
				if (edad == 30) cantidad.adultos++;
				else {
					cantidad.ninos++;
				}
			});
		});
		return cantidad;
	});

	function getDefaultDates() {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		const dayAfterTomorrow = new Date(tomorrow);
		dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
		return [tomorrow, dayAfterTomorrow];
	}

	function initializeFiltros() {
		const storeFilters = store_buscador.filtrosSeleccionadosBuscador;
		const defaultDates = getDefaultDates();
		if (Object.keys(route.query).length === 0) {
			store_buscador.setFiltrosSeleccionadosBuscador(
				storeFilters.destino,
				storeFilters.fechasAlojamiento || defaultDates,
				storeFilters.fechasForfait || defaultDates,
				storeFilters.habitaciones || [{ habitacion: [30, 30] }]
			);
		} else {
			const { destino, fechasAlojamiento, fechasForfait, habitaciones } = route.query;
			store_buscador.setFiltrosSeleccionadosBuscador(
				destino ? JSON.parse(destino) : storeFilters.destino,
				fechasAlojamiento ? JSON.parse(fechasAlojamiento) : storeFilters.fechasAlojamiento || defaultDates,
				fechasForfait ? JSON.parse(fechasForfait) : storeFilters.fechasForfait || defaultDates,
				habitaciones ? JSON.parse(habitaciones) : storeFilters.habitaciones || [{ habitacion: [30, 30] }]
			);
		}
	}

	function setDestino(nombre, estacionId) {
		store_buscador.setDestino(nombre, estacionId);
	}

	function setHabitaciones(habitacion) {
		store_buscador.setHabitaciones(habitacion);
	}

	function validateHabitaciones() {
		if (store_buscador.filtrosSeleccionadosBuscador.habitaciones === null) {
			store_buscador.setHabitaciones([{ habitacion: [30, 30] }]);
		}
	}

	function getDays(start, last) {
		const date1 = new Date(start);
		const date2 = new Date(last);

		const oneDay = 1000 * 60 * 60 * 24;

		const diffTime = date2.getTime() - date1.getTime();

		const diffDays = Math.round(diffTime / oneDay);
		return diffDays;
	}

	function goToViajesAMedida() {
		if (landing.value && filtrosSeleccionadosBuscador.value.fechasAlojamiento != null) {
			let fechaInicio = new Date(filtrosSeleccionadosBuscador.value.fechasAlojamiento[0]);

			store_buscador.setFechasForfait(
				new Date(fechaInicio.setDate(fechaInicio.getDate() + 1)),
				filtrosSeleccionadosBuscador.value.fechasAlojamiento[1]
			);
		}

		if (!checkNoErrors()) {
			return;
		}

		router.push({
			name: Buscador,
			query: {
				destino: JSON.stringify(filtrosSeleccionadosBuscador.value.destino),
				fechasAlojamiento: JSON.stringify(filtrosSeleccionadosBuscador.value.fechasAlojamiento),
				fechasForfait: JSON.stringify(filtrosSeleccionadosBuscador.value.fechasForfait),
				habitaciones: JSON.stringify(filtrosSeleccionadosBuscador.value.habitaciones),
			},
		});
	}

	function setDestinoAndClose(nombre, estacionId) {
		setDestino(nombre, estacionId);
		showDestinosModal.value = false;
	}

	function closeDestinosModal() {
		showDestinosModal.value = false;
	}

	function closeFechasAlojamientoModal() {
		showFechasAlojamientoModal.value = false;
	}

	function acceptFechasAlojamiento() {
		showFechasAlojamientoModal.value = false;
	}

	function closeFechasForfaitModal() {
		showFechasForfaitModal.value = false;
	}

	function acceptFechasForfait() {
		showFechasForfaitModal.value = false;
	}

	function closeHabitacionesModal() {
		showHabitacionesModal.value = false;
	}

	function acceptHabitaciones() {
		validateHabitaciones();
		showHabitacionesModal.value = false;
	}

	function checkNoErrors() {
		errors.value.destino = filtrosSeleccionadosBuscador.value.destino.nombre == null ? true : false;

		errors.value.fechasAlojamiento = filtrosSeleccionadosBuscador.value.fechasAlojamiento == null ? true : false;

		errors.value.fechasForfait = filtrosSeleccionadosBuscador.value.fechasForfait == null ? true : false;

		errors.value.habitaciones = filtrosSeleccionadosBuscador.value.habitaciones == null ? true : false;

		return !(
			errors.value.destino ||
			errors.value.fechasAlojamiento ||
			errors.value.fechasForfait ||
			errors.value.habitaciones
		);
	}

	async function loadDestinationOptions() {
		await store_buscador.setFiltros();
	}

	watch(fechaALojamiento, () => {
		if (filtrosSeleccionadosBuscador.value.fechasForfait) {
			let fechaInicio = new Date(filtrosSeleccionadosBuscador.value.fechasAlojamiento[0]);
			let fechafin = new Date(filtrosSeleccionadosBuscador.value.fechasAlojamiento[1]);
			let yesterday = new Date();

			if (getDays(fechaInicio, fechafin) > 2) {
				yesterday = new Date(fechafin.getTime());
				yesterday.setDate(fechafin.getDate() - 1);
				store_buscador.setFechasForfait(new Date(fechaInicio.setDate(fechaInicio.getDate() + 1)), yesterday);
			} else {
				store_buscador.setFechasForfait(
					new Date(fechaInicio.setDate(fechaInicio.getDate() + 1)),
					filtrosSeleccionadosBuscador.value.fechasAlojamiento[1]
				);
			}
		}
	});

	watch(
		() => route.query,
		() => {
			initializeFiltros();
		},
		{ deep: true }
	);

	onMounted(() => {
		initializeFiltros();
		loadDestinationOptions();
	});
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/main.scss';

	.error {
		color: red;
	}

	.error_select {
		border: 1px solid red;
	}

	.text-clickable:hover {
		text-decoration: underline;
		cursor: pointer;
	}

	.section-search {
		border-right: 1.5px solid var(--bs-primary);
	}

	.mobile-buscador {
		display: none;

		.title-buscador-mobile {
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		.title-opcion-mobile {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			color: var(--bs-primary);
		}

		.btn-mobile {
			font-size: 13px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.buscador {
		display: flex;
		height: 80px;
		background-color: rgba(255, 255, 255, 0.7);
		box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);
		border-radius: var(--bs-border-radius-xl);
		padding: 0.2rem 2rem;
		border-radius: var(--bs-border-radius-xxl);
		color: var(--bs-primary);
	}

	.width-buscador-sin-forfait {
		max-width: 780px;
	}

	.width-buscador-con-forfait {
		width: 1050px;
	}

	.buscador-subtitulo {
		position: relative;
	}

	.buscador-titulo {
		font-size: 18px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: 0.15px;
		padding-top: 0;
	}

	.dropdown-destino {
		width: max-content;
		min-width: 600px;
		max-width: 800px;
	}

	.error {
		color: red;
	}

	.error_select {
		border: 1px solid red;
	}

	@media only screen and (max-width: 989px) {
		.buscador {
			display: none;
		}

		.container {
			background-color: rgba(255, 255, 255, 0.7);
		}
		.mobile-buscador {
			display: block;
			width: 100%;
			padding: 15px;
			border-radius: var(--bs-border-radius-xxl);
		}
		.buscador {
			width: 100%;
			height: auto;
			border-radius: var(--bs-border-radius-xl);
			padding: 10px;
		}

		.box-shadow-buscador {
			box-shadow: 0 0 7px 0 rgba(var(--bs-primary-rgb), 0.5);
		}
	}

	.text-muted {
		font-size: 0.8rem;
	}

	@media (min-width: 576px) {
		.modal-dialog {
			max-width: 900px;
		}
	}
</style>
