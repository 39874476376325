<template>
	<div>
		<div class="card text-primary">
			<div class="card-body">
				<ExclamationIcon :size="'20'" />
				<p class="card-text pt-2">
					<i18n-t :keypath="'cardReservaPlazasLibres.descripcion'" tag="div">
						<template #plazasLibres>
							<span class="fw-bold">{{ plazasLibres }}</span>
						</template>
						<template #pagoPendiente>
							<span> {{ pagoPendiente }}{{agenciaStore.getCurrency}}</span>
						</template>
					</i18n-t>
				</p>
				<ul>
					<li>{{ $t('cardReservaPlazasLibres.opcionUno') }}</li>
					<li>
						<span class="inline-or-row">
							<i18n-t :keypath="'cardReservaPlazasLibres.opcionDos'" tag="div" class="inline-or-row">
								<template #pagoPendiente>
									<span class="px-2"> {{ pagoPendiente }}{{agenciaStore.getCurrency}} </span>
								</template>
							</i18n-t>
							<span class="text-msj px-2"> ({{ precioPorPersona }} {{agenciaStore.getCurrency}} {{ $t('general.porPersona') }}) </span>
						</span>
					</li>
				</ul>

				<i18n-t :keypath="'cardReservaPlazasLibres.cancelacion'" tag="p">
					<template #fecha>
						<span class="fw-bold">{{ formatDate(fechaCancelacion) }}</span>
					</template>
					<template #final>
						<span class="fw-bold">{{ $t('cardReservaPlazasLibres.cancelacionBold') }}</span>
					</template>
				</i18n-t>

				<div class="text-center buttons row pt-2">
					<div class="col">
						<button class="btn btn-link" @click="$emit('abonarPago')">
							{{ $t('cardReservaPlazasLibres.button.abonarPago') }}
						</button>
					</div>

					<div class="col">
						<button class="btn btn-primary" @click="$emit('copiarLink')">
							{{ $t('cardReservaPlazasLibres.button.copiarLink') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ExclamationIcon from 'src/components/Icons/ExclamationIcon.vue';
	import { computed } from 'vue';
	import { formatDate } from '@/helpers/dateUtiles';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	const props = defineProps({
		plazasLibres: {
			type: Number,
			required: true,
		},
		pagoPendiente: {
			type: Number,
			required: true,
		},
		fechaCancelacion: {
			type: String,
			required: true,
		},
		plazasOcupadas: {
			type: Number,
			required: true,
		},
	});

	const agenciaStore = agenciaStoreModule();

	defineEmits(['abonarPago', 'copiarLink']);

	const precioPorPersona = computed(() => Number((props.pagoPendiente / props.plazasOcupadas).toFixed(2)));
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/main.scss';

	@include media-breakpoint-down(lg) {
		.card-body {
			font-size: 14px;
			.card-text {
				font-weight: 400;
			}
		}
	}

	.card {
		margin: auto;
		border-radius: var(--bs-border-radius-xl);
		font-size: 1.125rem;

		.buttons {
			button {
				font-size: 0.875rem;
			}
		}
	}

	.card-body {
		padding: 20px;
	}

	.text-msj {
		color: var(--bs-primary);
		font-size: 12px;
		font-weight: 400;
	}
	.inline-or-row {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
</style>
