const DATA_PAGES = {
	skiandnight: {
		title: 'home.banner.title',
		subTitle: 'home.banner.subtitle',
		titleFestivales: 'home.festivales.title',
		subTitleFestivales: 'home.festivales.subtitle',
		alternative: 'home.banner.experienciasAnyo',
		textBtn: 'home.banner.experienciasAMedida',
		textBtnExperiencias: 'alojamiento.todasExperiencias',
	},
	bsspain: {
		title: 'home.banner.titleBsspain',
		titleFestivales: 'home.festivales.titleBsspain',
		subTitleFestivales: 'home.festivales.subtitleBsspain',
		domain: 'bsspain',
		textBtn: null,
		textBtnExperiencias: 'alojamiento.todasExperiencias',
	},
	['poker-red']: {
		title: 'home.banner.titlePr',
		titleFestivales: 'home.festivales.titlePr',
		subTitleFestivales: 'home.festivales.subtitlePr',
		domain: 'poker-red',
		textBtn: 'home.buscadorSection.button',
		textBtnExperiencias: 'general.reservar',
		urlRedirect: 'poker-red.travel/agrupaciones',
	},
};

export const getPageData = () => {
	const domain = ['bsspain', 'poker-red'].find(domain => checkDomain(domain)) || 'skiandnight';
	return DATA_PAGES[domain];
};

export function checkDomain(domains) {
	const domainsArray = Array.isArray(domains) ? domains : [domains];
	return domainsArray.some(domain => window.location.host.includes(domain));
}
