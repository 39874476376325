<template>
	<div class="header-festival pt-3 w-100 flex-column justify-content-center align-items-center" :style="headerStyle">
		<div class="h-100 d-flex w-100 flex-column justify-content-between align-items-center">
			<div class="text-center my-auto pt-5 mx-5 px-5 container-mobile">
				<div
					class="title-desktop fw-bold mb-4 pb-3"
					:style="{ color: festival.colorTextoCabecera || 'var(--bs-primary)' }">
					{{ festival.titulo }}
				</div>

				<div class="d-flex flex-column align-items-center mt-3 mt-4">
					<button
						v-if="(festival.viajes && festival.viajes.length > 0) || checkDomain('poker-red')"
						class="btn btn-primary mt-2"
						@click="goVerTodas">
						{{ $t(getPageData().textBtnExperiencias) }}
					</button>
					<button v-if="festival.mostrarConseguirEntrada" class="btn btn-primary" @click="goEntradaFestival">
						{{ $t('alojamiento.consigueEntrada') }}
					</button>
					<button
						v-if="festival.mostrarConseguirEntradaConAlojamiento"
						class="btn btn-primary mt-2"
						@click="goBuscadorFestival">
						{{ $t('alojamiento.verTodasExperiencias') }}
					</button>
				</div>
			</div>

			<div class="row text-center w-100 m-0 p-0 position-absolute bottom-0">
				<div class="col-lg-12 d-flex justify-content-center w-100 m-0 p-0">
					<div
						class="date-location-container d-flex flex-wrap justify-content-center align-items-center info-container flex w-100 text-white"
						:style="{
							backgroundColor: festival.colorTextoCabecera || 'var(--bs-primary)',
							filter: 'drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.5))',
						}">
						<span class="date-item d-flex align-items-center text-white me-4 translate">
							<calendary-icon class="base-modal__icon me-2" size="16px" color="white" />
							{{ dateFromToFestival }}
						</span>
						<span class="separator"></span>
						<div>
							<span class="location-item d-flex align-items-center text-white me-1 my-1">
								<span class="location-text text-wrap">
									<location-icon class="base-modal__icon me-2" size="18px" color="white" width="16px" />
									<span>{{ festival.ubicacion }}</span>
									<button
										class="btn btn-link text-white me-0 ver-mapa-link"
										data-bs-toggle="modal"
										data-bs-target="#mapaModalFestival">
										{{ $t('alojamiento.verMapa') }}
									</button>
								</span>
							</span>
						</div>
						<span class="separator"></span>
						<span class="image-item d-flex align-items-center text-white">
							<share2-icon class="base-modal__icon me-2" size="16px" color="white" />
							<a
								href="#"
								class="fw-normal white text-center pointer text-decoration-underline hover-secondary"
								@click="sharePage($event)">
								{{ $t('alojamiento.compartir') }}
							</a>
						</span>
					</div>
				</div>
			</div>
			<div
				id="mapaModalFestival"
				class="modal fade"
				tabindex="-1"
				aria-labelledby="mapaModalFestivalLabel"
				aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 id="mapaModalFestivalLabel" class="modal-title">{{ $t('alojamiento.ubicacion') }}</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
						</div>
						<div class="modal-body">
							<GMapMap :center="posicion" :zoom="8" map-type-id="terrain" style="min-height: 60vh">
								<GMapMarker :position="posicion" :clickable="true" :draggable="false" />
							</GMapMap>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	import { computed } from 'vue';
	import { useRouter } from 'vue-router';
	import LocationIcon from '@/components/Icons/LocationIcon.vue';
	import CalendaryIcon from '@/components/Icons/CalendaryIcon.vue';
	import Share2Icon from '@/components/Icons/Share2Icon.vue';
	import { useI18n } from 'vue-i18n';
	import { ViajesGrupoCatalogo } from '@/router/RouterNames'; // Assuming this is imported from your router setup
	import { checkDomain, getPageData } from '@/helpers/domainUtils';

	const props = defineProps({
		festival: {
			type: Object,
			required: true,
		},
		uri: {
			type: String,
			required: true,
		},
	});

	const router = useRouter();
	const { t } = useI18n();

	const dateFromToFestival = computed(() => {
		const dateFromFestival = new Date(props.festival.fechaInicio).toLocaleDateString('es-ES', {
			day: 'numeric',
			month: 'short',
		});
		const dateToFestival = new Date(props.festival.fechaFin).toLocaleDateString('es-ES', {
			day: 'numeric',
			month: 'short',
		});
		return `${dateFromFestival} - ${dateToFestival}`;
	});

	const headerStyle = computed(() => ({
		'background-image': props.festival.imagenDesktop
			? `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${props.festival.imagenDesktop})`
			: 'linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7))',
		'background-size': 'cover',
		'background-position': 'center',
	}));

	const posicion = computed(() => ({
		lat: props.festival.latitud,
		lng: props.festival.longitud,
	}));

	function goEntradaFestival() {
		router.push({ name: 'FestivalesCatalogo', params: { uri: props.uri } });
	}

	function checkRedirectPageExternal() {
		const pageData = getPageData();

		if (pageData.urlRedirect) {
			window.location.href = `https://${pageData.urlRedirect}`;
			return true;
		}
		return false;
	}

	function goBuscadorFestival() {
		router.push({
			name: 'GruposCatalogo',
			query: {
				festival: props.festival.titulo,
			},
		});
	}

	function goVerTodas() {
		if (!checkRedirectPageExternal()) {
			router.push({
				name: ViajesGrupoCatalogo,
				query: { agrupacion: props.festival.titulo },
			});
		}
	}

	function sharePage(event) {
		event.preventDefault();
		if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: t('alojamiento.compartirLanding'),
					url: window.location.href,
				})
				.then(() => {})
				.catch(() => {});
		}
	}
</script>

<style scoped>
	.header-festival {
		background-repeat: no-repeat;
		height: 85vh;
		position: relative;
		background-size: cover;
		background-position: center;
	}

	.title-desktop {
		font-size: 38px;
		color: white;
	}

	.date-location-container {
		padding: 25px;
		font-weight: 17.5px;
		justify-content: space-between;
	}

	.date-item,
	.location-item,
	.image-item {
		white-space: nowrap;
		color: white !important;
	}

	.separator {
		border-left: 1px solid white;
		height: 20px;
		margin: 0 10px;
	}

	.hover-secondary:hover,
	.ver-mapa-link:hover {
		color: var(--bs-secondary) !important;
		transition: color 0.3s ease;
	}

	.location-text {
		word-break: break-word;
		white-space: normal;
	}

	.btn-primary {
		font-size: 14px;
		padding: 0.75rem 2rem;
	}

	.ver-mapa-link {
		font-size: 13px;
	}

	.separator {
		border-left: 1px solid white;
		height: 20px;
		margin: 0 35px;
	}

	@media (max-width: 1024px) {
		.separator {
			display: none;
		}
		.date-location-container[data-v-5bcaac28] {
			gap: 10px;
		}
		.ver-mapa-link {
			font-size: 10px;
		}
	}

	@media (max-width: 768px) {
		.header-festival {
			height: 80vh;
		}

		.container-mobile {
			padding: 0 10px !important;
		}

		.title-desktop {
			font-size: 28px;
		}

		.date-location-container {
			font-size: 14px;
			flex-wrap: wrap;
			justify-content: center;
		}

		.date-item {
			margin-right: 1rem;
			word-break: break-word !important;
		}

		.date-item:not(:last-child)::after {
			content: none;
		}

		.btn-primary {
			font-size: 14px;
			padding: 0.5rem 1.5rem;
		}
	}
</style>
