import moment from 'moment';
import { useI18n } from 'vue-i18n';

export const namesMonths = (lan = 'es-Es') => {
	if (lan === 'es-Es') {
		return {
			0: 'Enero',
			1: 'Febrero',
			2: 'Marzo',
			3: 'Abril',
			4: 'Mayo',
			5: 'Junio',
			6: 'Julio',
			7: 'Agosto',
			8: 'Septiembre',
			9: 'Octubre',
			10: 'Noviembre',
			11: 'Diciembre',
		};
	} else {
		return {
			0: 'January',
			1: 'February',
			2: 'March',
			3: 'April',
			4: 'May',
			5: 'June',
			6: 'July',
			7: 'August',
			8: 'September',
			9: 'October',
			10: 'November',
			11: 'December',
		};
	}
};

export function formatDateDDmm(date) {
	moment.locale(useI18n().locale.value);
	return moment(date, 'YYYY-MM-DD').format('DD/MM');
}

export function formatDateDDmmm(date) {
	moment.locale(useI18n().locale.value);
	return moment(date, 'YYYY-MM-DD').format('DD MMM');
}

export function formatDateDDmmmm(date) {
	moment.locale(useI18n().locale.value);
	return moment(date, 'YYYY-MM-DD').format('DD MMMM');
}

export function formatDateDDmmmmYYYY(date) {
	moment.locale(useI18n().locale.value);
	return moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY');
}

export function formatDateIntervalDDmmm(initDate, endDate) {
	moment.locale(useI18n().locale.value);
	const from = moment(initDate, 'YYYY-MM-DD');
	const to = moment(endDate, 'YYYY-MM-DD');

	return `${from.format('DD MMM')} - ${to.format('DD MMM')}`;
}

export function formatDateDDmmmYYYY(date) {
	moment.locale(useI18n().locale.value);
	return moment(date, 'YYYY-MM-DD').format('ll');
}

export function fomatDatesHyphen(date) {
	return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
}

export function formatDate(date) {
	return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
}

export function fomatDatesHyphenPost(date) {
	return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
}

export function formatDateWithWeekDay(date) {
	moment.locale(useI18n().locale.value);
	return moment(date, 'YYYY-MM-DD').format('ddd, D MMM');
}

export const normalizeDate = dateString => {
	const date = new Date(dateString);
	date.setHours(0, 0, 0, 0);
	return date;
};

export const normalizeFromToDates = (start, end) => {
	moment.locale(useI18n().locale.value);
	const from = moment(start, 'YYYY-MM-DD');
	const to = moment(end, 'YYYY-MM-DD');

	if (from.month() === to.month()) {
		return `${from.format('DD')} - ${to.format('DD MMM')}`;
	}

	return `${from.format('DD MMM')} - ${to.format('DD MMM')}`;
};
