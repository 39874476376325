<template>
	<div class="row align-items-center justify-content-end">
		<div v-if="storeGlobal.permisos.rrss.showed" class="col-auto">
			<div class="hvr-grow social-icon">
				<a
					:href="instagram"
					target="_blank"
					class="bg-white bg-opacity-25 p-2 rounded-circle shadow"
					aria-label="Instragram link">
					<i class="fa-brands fa-instagram fa-lg" />
				</a>
			</div>
		</div>
		<div v-if="storeGlobal.permisos.rrss.showed" class="col-auto">
			<div class="hvr-grow social-icon">
				<a
					:href="tiktok"
					target="_blank"
					class="bg-white bg-opacity-25 p-2 rounded-circle shadow"
					aria-label="TikTok link">
					<i class="fa-brands fa-tiktok fa-lg" />
				</a>
			</div>
		</div>

		<div v-if="showHorarioAtencion" class="col-auto">
			<div class="caja-info shadow">
				{{ $t('header.horarioAtencion') }}
			</div>
		</div>
		<div class="col-auto d-flex">
			<div class="caja-info shadow hvr-grow social-icon email-container">
				<i class="fa-solid fa-envelope fa-xl" />
				<span class="mx-2">{{ agenciaStore.email }}</span>
			</div>
			<template v-if="windowLocation === 'https://skiandnight.com'">
				<a href="https://wa.me/+34910054547" target="_blank">
					<div class="caja-info shadow pointer hvr-grow social-icon">
						<i class="fa-brands fa-whatsapp fa-xl" />
						<span class="mx-2">{{ phone }}</span>
					</div>
				</a>
			</template>
			<template v-else-if="windowLocation.includes('bodosound')">
				<a href="https://wa.me/+34682833813" target="_blank">
					<div class="caja-info shadow pointer hvr-grow social-icon">
						<i class="fa-brands fa-whatsapp fa-xl" />
						<span class="mx-2"> +34 682 833 813 </span>
					</div>
				</a>
			</template>
			<template v-else>
				<div class="caja-info shadow social-icon">
					<i class="fa-solid fa-phone fa-xl" />
					<span class="mx-2">{{ phoneNumber }}</span>
				</div>
			</template>
		</div>
	</div>
</template>

<script setup>
	import { instagram, phone, tiktok } from '@/constants/socialMedias';
	import { appGlobalStore } from '@/store_pinia/app';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { computed } from 'vue';
	import { checkDomain } from '@/helpers/domainUtils';

	const agenciaStore = agenciaStoreModule();
	const storeGlobal = appGlobalStore();

	const windowLocation = computed(() => window.location.origin);

	const phoneNumber = computed(() => {
		return agenciaStore.getTelefono?.toString();
	});
	const showHorarioAtencion = computed(() => checkDomain(['skiandnight', 'poker-red']));
</script>

<style scoped lang="css">
	.social-icon {
		transition: 0.3s;
	}

	.social-icon:hover {
		color: var(--bs-secondary);
	}

	.caja-info {
		font-size: 0.8rem;
		text-align: center;
		font-weight: 400;
		padding: 0.3rem 1rem 0.3rem 1rem;
		background-color: rgba(255, 255, 255, 0.25);
		border-radius: var(--bs-border-radius);
	}

	.email-container {
		margin-right: 0.5rem;
	}
</style>
