import * as URLS from 'src/constants/agencies';

const AGENCY_CONFIGS = {
	[URLS.URL_SKIANDNIGHT]: {
		googleAds: 'AW-10838811708',
		googleSiteVerification: '2izWFZFYuLHJSxosZa5vbzigAT9G-fSVUCSKl3jxVo8',
		metaPixel: '1218568996103436',
	},
	[URLS.URL_SNOWDAZE]: {
		metaPixel: '510170116168037',
	},
	[URLS.URL_FESTIVALHIBERNATION]: {
		metaPixel: '1312335698842677',
	},
	[URLS.URL_LANOCHEVOYAGES]: {
		metaPixel: '942445239778586',
	},
	[URLS.URL_WSF]: {
		googleAds: 'G-EL9X1XCTF7',
	},
};

export function addScriptsHeadTags(GTM = null) {
	const currentOrigin = window.location.origin;
	const config = AGENCY_CONFIGS[currentOrigin];

	if (GTM) {
		addGoogleTagManager(GTM);
	}

	if (!config) return;

	const { googleAds, googleSiteVerification, metaPixel } = config;

	if (googleAds) {
		addGoogleAdsConversionTracking(googleAds);
	}

	if (googleSiteVerification) {
		addGoogleSiteVerificationMetaTag(googleSiteVerification);
	}

	if (metaPixel) {
		addMetaPixelTag(metaPixel);
	}
}

function addGoogleTagManager(gtmId) {
	if (!gtmId) return;
	const head = document.head || document.getElementsByTagName('head')[0];
	if (!head) {
		console.error('No head element found in the document.');
		return;
	}
	const scriptGoogleTag = document.createElement('script');
	scriptGoogleTag.textContent =
		"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
		"new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
		"j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
		"'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
		`})(window,document,'script','dataLayer','${gtmId}');`;

	head.appendChild(scriptGoogleTag);

	const iframe = document.createElement('iframe');
	iframe.style.display = 'none';
	iframe.style.visibility = 'hidden';
	iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
	document.body.appendChild(iframe);
}

function addGoogleAdsConversionTracking(adsConversionId) {
	const head = document.head || document.getElementsByTagName('head')[0];
	if (!head) {
		console.error('No head element found in the document.');
		return;
	}

	const scriptGoogleTagLink = document.createElement('script');
	scriptGoogleTagLink.async = true;
	scriptGoogleTagLink.src = `https://www.googletagmanager.com/gtag/js?id=${adsConversionId}`;
	head.appendChild(scriptGoogleTagLink);

	const scriptGoogleTagAws = document.createElement('script');
	scriptGoogleTagAws.textContent = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${adsConversionId}');`;
	head.appendChild(scriptGoogleTagAws);
}

function addGoogleSiteVerificationMetaTag(content) {
	const head = document.head || document.getElementsByTagName('head')[0];
	if (!head) {
		console.error('No head element found in the document.');
		return;
	}

	const meta = document.createElement('meta');
	meta.name = 'google-site-verification';
	meta.content = content;

	head.appendChild(meta);
}

function addMetaPixelTag(pixelId) {
	const head = document.head || document.getElementsByTagName('head')[0];
	if (!head) {
		console.error('No head element found in the document.');
		return;
	}

	// Create script element for Facebook Pixel
	const metaPixcelTag = document.createElement('script');
	metaPixcelTag.textContent = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${pixelId}');fbq('track', 'PageView');`;

	head.appendChild(metaPixcelTag);

	// Create image element for noscript pixel
	const image = document.createElement('img');
	image.height = 1;
	image.width = 1;
	image.style.display = 'none';
	image.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;

	head.appendChild(image);
}
